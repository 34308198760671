#wrapper {
    width: 100%;
    max-width: 725px;
    margin: 0px auto;
}

.gears-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Ensures spacing remains constant */
    position: relative;
    width: 100%; /* Ensures gears don't move */
    height: 100%;
}

/* Gear Base */
.gear {
    width: 200px;
    height: 200px;
    background-color: #0D6EFD;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: scale(1); /* Prevents size distortion */
}

/* Gear Teeth */
.gear::before {
    content: "";
    position: absolute;
    width: 280px;
    height: 280px;
    background: 
      linear-gradient(0deg, transparent 39%, #0D6EFD 39%, #0D6EFD 61%, transparent 61%),
      linear-gradient(60deg, transparent 42%, #0D6EFD 42%, #0D6EFD 58%, transparent 58%),
      linear-gradient(120deg, transparent 42%, #0D6EFD 42%, #0D6EFD 58%, transparent 58%);
    border-radius: 50%;
}

/* Center Circle */
.gear::after {
    content: "";
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 50%;
}

/* Left Gear - Adjusted for 4x size */
.gear-left {
    left: 130px; /* Adjusted spacing */
    animation: gear-rotate-left 1s linear infinite;
}

/* Right Gear - Adjusted for 4x size */
.gear-right {
    right: 280px; /* Adjusted spacing */
    top: 80px; 
    animation: gear-rotate 1s linear infinite;
}

/* Keyframe Animations */
@keyframes gear-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes gear-rotate-left {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
