@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Manrope:wght@200..800&family=Open+Sans&family=Raleway:wght@100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

.bot-message-content {
  font-size: 1rem;
  color: #333; /* Dark gray for readability */
  line-height: 1.6;
}

.bot-message-content p {
  margin: 1rem 0; /* Adds spacing between paragraphs */
}

.bot-message-content ol {
  margin: 1rem 0;
  padding-left: 1.5rem; /* Indent ordered list */
}

.bot-message-content ul {
  margin: 1rem 0;
  padding-left: 1.5rem;
  list-style-type: disc; /* Use bullets for unordered lists */
}

.bot-message-content li {
  margin-bottom: 0.5rem; /* Adds spacing between list items */
}

.bot-message-content a {
  color: #1a0dab; /* Blue color for links */
  text-decoration: underline;
}

.bot-message-content em {
  font-style: italic;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Slightly larger */
  }
  100% {
    transform: scale(1);
  }
}

.pulsing-icon {
  animation: pulse 1.5s infinite; /* Adjust duration as needed */
}

/* Container for the report content */
.report-container {
  font-family: "SF Pro", sans-serif;
  color: #302d2d;
  line-height: 1.6;
}

/* Paragraph styling */
.report-container p {
  margin-bottom: 1rem;
  font-size: 1rem;
}

/* Headings */
.report-container h3 {
  font-size: 1.75rem;
  color: #8e3b8d;
  margin-bottom: 1rem;
}

.report-container h4 {
  font-size: 1.5rem;
  color: #535252;
  margin-bottom: 0.75rem;
}

/* Ordered and unordered lists */
.report-container ul,
.report-container ol {
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.report-container li {
  margin-bottom: 0.5rem;
}

/* Preformatted text */
.report-container pre {
  background-color: #f8f8f8;
  padding: 1rem;
  overflow: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
}

/* Code blocks */
.report-container code {
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  background-color: #eaeaea;
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
}

/* Bold text */
.report-container strong {
  font-weight: 700;
}

/* Emphasized text */
.report-container em {
  font-style: italic;
}

/* Links */
.report-container a {
  color: #8e3b8d;
  text-decoration: none;
}

.report-container a:hover {
  text-decoration: underline;
}

/* Horizontal rules */
.report-container hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 2rem 0;
}

/* Admin Switch */
/* Toggle Switch Container */
.toggle-switch {
  position: relative;
  width: 60px;
  height: 30px;
}

/* Hidden Checkbox */
.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Label (Slider) */
.toggle-label {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #d3d3d3; /* Off state color */
  border-radius: 30px;
  transition: background-color 0.3s ease;
}

/* Circle (Thumb) */
.toggle-label::after {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  left: 2px;
  top: 2px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

/* Checked State */
.toggle-input:checked + .toggle-label {
  background-color: #4caf50; /* On state color */
}

.toggle-input:checked + .toggle-label::after {
  transform: translateX(30px);
}

/* Slide In Animation */
.slide-in {
  -webkit-animation: slide-in 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation: slide-in 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* Slide Out Animation */
.slide-out {
  -webkit-animation: slide-out 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation: slide-out 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
}
@keyframes slide-out {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
}
